import React from "react";
import "./Pricing.css";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <React.Fragment>
      <section className="content-container">
        <div className="columns">
          <ul className="price">
            <li className="col-header">Basic</li>
            <li className="grey">Free</li>
            <li>On call support</li>
            <li>1 application</li>
            <li>Report and insights data</li>
            <li></li>
            <li className="grey">
              <Link to="/ContactUs">
                <button className="button">Start now</button>
              </Link>{" "}
            </li>
          </ul>
        </div>

        <div className="columns">
          <ul className="price">
            <li className="col-header" style={{ backgroundColor: "#37387a" }}>
              Pro
            </li>
            <li className="grey">$ 240 / Month</li>
            <li>Include previous plan</li>
            <li>Unlimited Consultation</li>
            <li>2 - 15 Applications</li>
            <li>7 days Audit logs</li>
            <li></li>
            <li className="grey">
              <Link to="/ContactUs">
                <button className="button">Start now</button>
              </Link>{" "}
            </li>
          </ul>
        </div>

        <div className="columns">
          <ul className="price">
            <li className="col-header">Enterprise Custom</li>
            <li className="grey">Let's Talk</li>
            <li>Includes previous plans</li>
            <li>Unlimited Consultation</li>
            <li>Unlimiited applications</li>
            <li>Audit logs with full history</li>
            <li>24×7×365 premium support</li>
            <li className="grey">
              <Link to="/ContactUs">
                <button className="button">Start now</button>
              </Link>{" "}
            </li>
          </ul>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
