import React from "react";

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="content-container" style={{ paddingTop: "5%" }}>
        <div className="sec-1" style={{ margin: "2%" }}>
          {/* <h2>About Us</h2> */}
          Welcome to Mycodepay Ltd, your premier destination for comprehensive
          IT solutions. Specializing in DevOps excellence, infrastructure
          security, Cloudflare integration, on-premise support, and database
          management, we're committed to elevating your digital infrastructure
          to new heights.
        </div>
        <div className="sec-2" style={{ margin: "2%" }}>
          {/* <h2>About Us</h2> */}
          At Mycodepay Ltd, we understand the importance of seamless operations
          and robust security. Our team of experts is dedicated to optimizing
          your systems for performance, resilience, and compliance while
          safeguarding your data against evolving threats.
        </div>
        <div className="sec-3" style={{ margin: "2%" }}>
          {/* <h2>About Us</h2> */}
          Whether you're scaling your operations, fortifying your defenses, or
          seeking specialized support, we've got you covered. Partner with us to
          unlock the full potential of your digital ecosystem and embark on a
          journey of efficiency, security, and success.
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
